function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

function randomBetween(x, y) {
  return Math.floor(Math.random() * y) + x
}

function colorOfSquare(lastColor) {
  var colors = ['orange', 'petrol', 'gelb', 'gruen', 'blau', 'violett'];
  var color = colors[randomBetween(0, 6)];
  while(color == lastColor){
    var color = colors[randomBetween(0, 6)];
  }
  return color;
}

function colorSquares(squares) {

  $('.colorSquare').remove();

  var xy = 100;
  var windowWidth = 1700;
  if($(window).width() <= 540){
    xy = 50;
    windowWidth=$(window).width();
  }

  var x=0;
  var y=0;

  var colors = ['orange', 'petrol', 'gelb', 'gruen', 'blau', 'violett'];
  var lastColor = false;
  var color = 0;
  var fadeOut = '';
  var delays = shuffle([2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.2]);
  var delayIndex = -1;

  for(var i = 0; i < squares; i++) {
    color = colorOfSquare(lastColor);

    fadeOut='';
    fadeTextX = (xy * 6);
    fadeTextY = (xy * 2);
    if(x< fadeTextX && y < fadeTextY) {
      fadeOut = 'fadeOut';
      delayIndex = delayIndex + 1;
    }

    $('.bannerSpecial').append('<div class="colorSquare ' + color + ' ' + fadeOut + '" style="left:' + x + 'px;top:' + y + 'px;animation-delay: ' + delays[delayIndex] + 's;"></div>');
    lastColor = color;
    x=x+xy;
    if(x > windowWidth){
      x=0;
      y=y+xy;
    }
  }
}

function squareFade(suqres) {
  var pickSquare = randomBetween(0, suqres);
  var colors = ['#ff6d00', '#048174', '#f0dc00', '#c7d300', '#b9e0de', '#5c004e'];
  var color = randomBetween(0, 6);
  $('.colorSquare:eq(' + pickSquare + ')').css({'background': colors[color]});
}

$(function() {
  isSmall = 0;
  var squares = 100;
  if($(window).width() <= 540){
      squares = 300;
  }
  colorSquares(squares);
  window.setInterval(function () {squareFade(squares)}, 1000);
  $('.logoHolder').show();

  $(window).resize(function(){
    console.log('AAAAA');
    if($(window).width() <= 540){
      if(isSmall == 0) {
        isSmall = 1;
        colorSquares(300);
      }
    }
    else {
      if(isSmall == 1) {
        isSmall = 0;
        colorSquares(100);
      }
    }
  });
});
